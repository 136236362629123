import {
  IcFluentClock24Filled,
  IcFluentComment24Filled,
  IcFluentEyeShow24Regular,
  IcFluentPlayCircle24Filled,
  Tooltip } from
'@flipgrid/flipkit';
import classNames from 'classnames';
import { has } from 'lodash-es';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import ProfilePicture from './ProfilePicture';
import GlobalContext from '~/contexts/globalContext';
import { getEngagementTimeFormat } from '~/helper/dates';
import { formatName } from '~/helper/formatting';
import engagementStatsStyles from '~/styles/components/Utility/components/EngagementStats.css';

import type { LinksFunction } from '@remix-run/node';
import type { DisplayGroup, Topic, TopicCreator } from 'types';
import type { Group } from 'types/Group';

export const links: LinksFunction = () => [{ rel: 'stylesheet', href: engagementStatsStyles }];

type Props = {
  group: Group | DisplayGroup;
  topic?: Topic;
  topicCreatorInfo?: TopicCreator;
};

const isTypeGroup = (group: Group | DisplayGroup): group is Group => {
  // group should have the logged in user id, whereas a DisplayGroup should not
  return has(group, 'user.id');
};

const EngagementStats = ({ group, topic, topicCreatorInfo }: Props) => {
  const { breakPoints } = useContext(GlobalContext);
  const { t } = useTranslation();

  const filteredUsers = isTypeGroup(group) ? group?.users?.filter((u) => u.role !== 'pending') : group?.users; // only groups of type Group have pending members, filter pending members out
  const count = !breakPoints.isScreenSmall ? 1 : 3;
  const names = filteredUsers?.slice(0, count).map((u) => formatName(u, true));
  const icons = filteredUsers?.slice(0, count);
  const overflowUserCount = filteredUsers ? filteredUsers.length - count : 0;
  // TODO: localize this
  const copilots = names ? `${names.join(', ')}${overflowUserCount > 0 ? ` and ${overflowUserCount} more` : ``}` : '';

  const data = topic || ('resource_type' in group && group?.resource_type !== 'grid' ? group.grid : group);

  const isLoggedOutGroup = ('resource_type' in data);

  return (
    <div className={classNames('engagementStats__leadInfoWrapper', { '-topic': topic })}>
      {!topic &&
      <div className="engagementStats__leadImagesWrapper">
          <span className="engagementStats__leadImages">
            {icons?.map((u, index) =>
          <span className="engagementStats__leadImage" key={u.id || index}>
                <ProfilePicture image_url={u.image_url} id={u.id} />
              </span>
          )}
          </span>
          <span className={classNames('engagementStats__stats -copilotNames', { color__white: !topic })}>
            {copilots}
          </span>
        </div>}

      {topic && topicCreatorInfo &&
      <div className="engagementStats__leadImagesWrapper">
          <span className="engagementStats__leadImages">
            <span className="engagementStats__leadImage" key={topicCreatorInfo.id}>
              <ProfilePicture image_url={topicCreatorInfo.image_url} id={topicCreatorInfo.id} />
            </span>
          </span>
          <span className={classNames('engagementStats__stats -copilotNames', { color__white: !topic })}>
            {topicCreatorInfo.display_name}
          </span>
        </div>}

      {group.membership && !isLoggedOutGroup &&
      <>
          <Tooltip label={t('common.responses')}>
            <div
            className={classNames('engagementStats__stats', { color__white: !topic })}
            aria-label={t('common.responses')}>

              <IcFluentPlayCircle24Filled className={classNames({ iconFill__white: !topic })} />
              {data.response_count}
              <span className="fk-visuallyHidden">{t('common.responses')}</span>
            </div>
          </Tooltip>

          {topic &&
        <>
              <Tooltip label={t('common.views')}>
                <div
              className={classNames('engagementStats__stats', { color__white: !topic })}
              aria-label={t('common.views')}>

                  <IcFluentEyeShow24Regular className={classNames({ iconFill__white: !topic })} />
                  {data.view_count}
                  <span className="fk-visuallyHidden">{t('common.views')}</span>
                </div>
              </Tooltip>
              <Tooltip label={t('common.comments')}>
                <div
              className={classNames('engagementStats__stats', { color__white: !topic })}
              aria-label={t('common.comments')}>

                  <IcFluentComment24Filled className={classNames({ iconFill__white: !topic })} />
                  {data.reply_count}
                  <span className="fk-visuallyHidden">{t('common.comments')}</span>
                </div>
              </Tooltip>
            </>}

          <div className={classNames('engagementStats__stats', { color__white: !topic })}>
            <>
              <IcFluentClock24Filled className={classNames({ iconFill__white: !topic })} />
              {t('groupHeader.hoursOfDiscussion', {
              hoursDecimal: getEngagementTimeFormat(data.engagement_length)
            })}
            </>
          </div>
        </>}

    </div>);

};

export default EngagementStats;